import * as React from 'react';

const HomeIcon = ({
  style = { width: '20px', margin: '0 24px' },
  width = '20px',
  height = '20px',
  stroke = '#00FFB3',
  className = '',
  viewBox = '0 0 20 20',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon ${className || ''}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={style.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.375 9.375L10 .625.625 9.375M3.125 10.625v8.75h5v-5h3.75v5h5v-8.75" />
    </g>
  </svg>
);

export default HomeIcon;
